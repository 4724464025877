.error-content {
    width: 100%;
    height: 100%;
    align-items: center;
    text-align: center;
    display: grid;
    gap: 0px
}

.error-icon {
    font-size: 60px;
    width: 100%;
}

.error-message {
    font-size: 14px;
}