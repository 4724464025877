.form-error-text-div-container {
  width: 100%;
  text-align: center;
  padding-bottom: 10px;
}

.form-error-text {
  text-align: "center";
  font-weight: "bold";
  font-size: 12;
  color: var(--ion-color-danger);
  width: 100%;
  padding: 5px;
}
