.data-input-border-radius {
    border-radius: 5px;
}

.data-input-border {
    border: 1px solid var(--ion-color-medium);
}

.data-input-border-danger {
    border: 1px solid var(--ion-color-danger);
}
