.info-card .highlighter {
    height: 5px;
    background-color: var(--ion-color-primary);
}

.info-card .icon {
    vertical-align: middle;
}

.header {
    font-size: 25px;
    font-weight: bold;
}
