.profile-card-title {
  font-weight: bold;
  font-size: 20px;
  vertical-align: middle;
}

.profile-card-content {
  vertical-align: middle;
}

.profile-card-logout-title {
  font-weight: 1000;
  font-size: 20px;
}
